<template>
    <div class="d-flex flex-column">
        <h2>{{ entity.pluralTitle }}</h2>

        <schema-table
            v-bind="$attrs"
            :entity-key="entityKey"
            :filter="filter"
            class="flex-grow-1">
            <template v-slot:above>
                <schema-filter 
                    v-model="filter"
                    class="flex-grow-1"
                    :entity-key="entityKey"
                    v-bind="$attrs" />
            </template>
        </schema-table>
    </div>
</template>

<script>
import schemaMixin from "@/features/schemas/mixins/schemaMixin";

export default {
    mixins:[schemaMixin],

    data() {
        return { 
            filter: {} 
        }
    }
};
</script>